import { Routes, Route } from "react-router-dom"
import Raspadita from "./Raspadita"
import Ruleta from "./Ruleta"
import Inicio from "./Inicio"
import InicioCodigo from "./InicioCodigo";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/:codigo" element={<InicioCodigo />} />
        <Route path="/raspadita/:codigo" element={<Raspadita />} />
        <Route path="/ruleta/:codigo" element={<Ruleta />} />        
      </Routes>
    </div>
  );
}

export default App;
