import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import IMAGES from "./assets";
import "./Raspadita.css";
import axios from "axios";

const CartonRaspadita = ({
  premio, 
  habilitado,
  onGanador,
  imgSeleccionada
}) => {
    let params = useParams()
    const [codigoURL, setCodigoURL] = useState(params.codigo);
    const [prizeValue, setPrizeValue] = useState(""); // Default value
    const [imagenRaspada, setImagenRaspada] = useState(null);
    let raspoGanasteFlag1 = false;
    let ganador = "";
    let raspoGanasteFlag2 = false;
    useEffect(() => {
        const canvasElement = document.getElementById("scratch");
        const canvasContext = canvasElement.getContext("2d");
        let isDragging = false;
        let imagen = "";
        if(imgSeleccionada===1){
            imagen = IMAGES.raspaganacompleto1
        } else if(imgSeleccionada===2){
            imagen = IMAGES.raspaganacompleto2
        } else if(imgSeleccionada===3){
            imagen = IMAGES.raspaganacompleto3
        } else if(imgSeleccionada===4){
            imagen = IMAGES.raspaganacompleto4
        } else if(imgSeleccionada===5){
            imagen = IMAGES.raspaganacompleto5
        } else if(imgSeleccionada===6){
            imagen = IMAGES.raspaganacompleto6
        } else if(imgSeleccionada===7){
            imagen = IMAGES.raspaganacompleto7
        } else if(imgSeleccionada===8){
            imagen = IMAGES.raspaganacompleto8
        }

        if(imgSeleccionada===1){
            setImagenRaspada(IMAGES.raspaganaraspado1)
        } else if(imgSeleccionada===2){
            setImagenRaspada(IMAGES.raspaganaraspado2)
        } else if(imgSeleccionada===3){
            setImagenRaspada(IMAGES.raspaganaraspado3)
        } else if(imgSeleccionada===4){
            setImagenRaspada(IMAGES.raspaganaraspado4)
        } else if(imgSeleccionada===5){
            setImagenRaspada(IMAGES.raspaganaraspado5)
        } else if(imgSeleccionada===6){
            setImagenRaspada(IMAGES.raspaganaraspado6)
        } else if(imgSeleccionada===7){
            setImagenRaspada(IMAGES.raspaganaraspado7)
        } else if(imgSeleccionada===8){
            setImagenRaspada(IMAGES.raspaganaraspado8)
        }
        

        const initializeCanvas = () => {
            /*const gradient = canvasContext.createLinearGradient(0, 0, 135, 135);
            gradient.addColorStop(0, "#d63031");
            gradient.addColorStop(1, "#fdcb6e");*/

            let img = new Image();
            img.src = imagen;
            img.alt="description of image";
            img.onload = function (e)
            {
                canvasContext.drawImage(img, 0, 0, 440, 220);
            }


            //canvasContext.fillStyle = gradient;
            //canvasContext.fillRect(0, 0, 400, 400);

            // Generate a random prize value from the available options
            const prizeOptions = [
                "$1", "$5", "$10", "$20", "$25", "$30", "$35", "$40", "$45", "$50"
            ];
            const randomPrize = prizeOptions[Math.floor(Math.random() * prizeOptions.length)];

            async function fetchPosts() {
                try {
                const response = await axios.get(
                  "https://"+process.env.REACT_APP_BACKEND_HOST+"/api/raspadita/jugar/"+codigoURL, {timeout: 10000} 
                );  
                return response
              }
              catch (err) {
                  console.error(err);
              }
              }
              fetchPosts().then(response=>{
          
                if(response.status == 200){ 
                  setPrizeValue(response.data?.resultado);
                  ganador=response.data?.resultado;
                } else {
                  window.location.reload();
                }      
              }) 

        };

        const scratch = (x, y) => {
            canvasContext.globalCompositeOperation = "destination-out";
            canvasContext.beginPath();
            canvasContext.arc(x, y, 12, 0, 2 * Math.PI);
            canvasContext.fill();    
            onGanador(ganador,raspoGanasteFlag1);       
        };

        const getMouseCoordinates = (event) => {
            const rect = canvasElement.getBoundingClientRect();
            const x = (event.pageX || event.touches[0].pageX) - rect.left;
            const y = (event.pageY || event.touches[0].pageY) - rect.top;
            return { x, y };
        };

        const handleMouseDown = (event) => {
          if(habilitado){
            isDragging = true;            
            const { x, y } = getMouseCoordinates(event);            
            scratch(x, y);
          }
       
        };

        const handleMouseMove = (event) => {
            if (isDragging) {
                event.preventDefault();
                const { x, y } = getMouseCoordinates(event);
                if(x > 100 && x<350 && y > 165 && y < 190){
                    raspoGanasteFlag1 = true;
                }                
                scratch(x, y);
            }
        };

        const handleMouseUp = () => {
            isDragging = false;
        };

        const handleMouseLeave = () => {
            isDragging = false;
        };

        const isTouchDevice = 'ontouchstart' in window;

        canvasElement.addEventListener(isTouchDevice ? "touchstart" : "mousedown", handleMouseDown);
        canvasElement.addEventListener(isTouchDevice ? "touchmove" : "mousemove", handleMouseMove);
        canvasElement.addEventListener(isTouchDevice ? "touchend" : "mouseup", handleMouseUp);
        canvasElement.addEventListener("mouseleave", handleMouseLeave);

        initializeCanvas();

        // Cleanup event listeners on unmount
        return () => {
            canvasElement.removeEventListener(isTouchDevice ? "touchstart" : "mousedown", handleMouseDown);
            canvasElement.removeEventListener(isTouchDevice ? "touchmove" : "mousemove", handleMouseMove);
            canvasElement.removeEventListener(isTouchDevice ? "touchend" : "mouseup", handleMouseUp);
            canvasElement.removeEventListener("mouseleave", handleMouseLeave);            
        };
    }, []);
//<h3>{prizeValue}</h3>
    return (
        <div className="container">
            <div className="base">
                <div className="base-texto">
                <div className="texto">{prizeValue}</div>
                </div>               
            </div>
            <canvas
                id="scratch"
                width="440"
                height="220"
                style={{
                    cursor: 'url("https://media.geeksforgeeks.org/wp-content/uploads/20231030101751/bx-eraser-icon.png"), auto',
                    backgroundImage: `url(${imagenRaspada})`
                }}
            ></canvas>
        </div>
    );
};
export default CartonRaspadita;