import background from "./background.png";
import logororomiem from "./logo-roromiem.png";
import textologo from "./texto-logo.png";
import logo from "./logo.png";
import raspaganaseleccion1 from "./raspa-gana-seleccionar-1.png";
import raspaganaseleccion2 from "./raspa-gana-seleccionar-2.png";
import raspaganaseleccion3 from "./raspa-gana-seleccionar-3.png";
import raspaganaseleccion4 from "./raspa-gana-seleccionar-4.png";
import raspaganaseleccion5 from "./raspa-gana-seleccionar-5.png";
import raspaganaseleccion6 from "./raspa-gana-seleccionar-6.png";
import raspaganaseleccion7 from "./raspa-gana-seleccionar-7.png";
import raspaganaseleccion8 from "./raspa-gana-seleccionar-8.png";

import raspaganacompleto1 from "./raspa-gana-completo-1.png";
import raspaganacompleto2 from "./raspa-gana-completo-2.png";
import raspaganacompleto3 from "./raspa-gana-completo-3.png";
import raspaganacompleto4 from "./raspa-gana-completo-4.png";
import raspaganacompleto5 from "./raspa-gana-completo-5.png";
import raspaganacompleto6 from "./raspa-gana-completo-6.png";
import raspaganacompleto7 from "./raspa-gana-completo-7.png";
import raspaganacompleto8 from "./raspa-gana-completo-8.png";


import raspaganaraspado1 from "./raspa-gana-raspado-1.png";
import raspaganaraspado2 from "./raspa-gana-raspado-2.png";
import raspaganaraspado3 from "./raspa-gana-raspado-3.png";
import raspaganaraspado4 from "./raspa-gana-raspado-4.png";
import raspaganaraspado5 from "./raspa-gana-raspado-5.png";
import raspaganaraspado6 from "./raspa-gana-raspado-6.png";
import raspaganaraspado7 from "./raspa-gana-raspado-7.png";
import raspaganaraspado8 from "./raspa-gana-raspado-8.png";


const IMAGES = {
    background,
    logororomiem,
    textologo,
    logo,
    raspaganaseleccion1,
    raspaganaseleccion2,
    raspaganaseleccion3,
    raspaganaseleccion4,
    raspaganaseleccion5,
    raspaganaseleccion6,
    raspaganaseleccion7,
    raspaganaseleccion8,
    raspaganacompleto1,
    raspaganacompleto2,
    raspaganacompleto3,
    raspaganacompleto4,
    raspaganacompleto5,
    raspaganacompleto6,
    raspaganacompleto7,
    raspaganacompleto8,
    raspaganaraspado1,
    raspaganaraspado2,
    raspaganaraspado3,
    raspaganaraspado4,
    raspaganaraspado5,
    raspaganaraspado6,
    raspaganaraspado7,
    raspaganaraspado8
    
}
export default IMAGES