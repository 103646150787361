import React, { Component, useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router-dom"
import Confetti from "react-confetti";
import CartonRaspadita from "./CartonRaspadita";
import useServiceData from "./useServiceData";
import Modal from 'react-modal';
import IMAGES from "./assets";
import Button from '@mui/material/Button';

const Raspadita = () => {
    let params = useParams()
    const [codigoURL, setCodigoURL] = useState(params.codigo);
    const [mostrarMsgGanador, setMostrarMsgGanador] = useState(false);


    const [numerosRandom1, setNumerosRandom1] = useState(null);
    const [numerosRandom2, setNumerosRandom2] = useState(null);
    const [numerosRandom3, setNumerosRandom3] = useState(null);
    const [numerosRandom4, setNumerosRandom4] = useState(null);
    const [numerosRandom5, setNumerosRandom5] = useState(null);
    const [numerosRandom6, setNumerosRandom6] = useState(null);
    const [numerosRandom7, setNumerosRandom7] = useState(null);
    const [numerosRandom8, setNumerosRandom8] = useState(null);

    const [imgNumerosRandom1, setImgNumerosRandom1] = useState(null);
    const [imgNumerosRandom2, setImgNumerosRandom2] = useState(null);
    const [imgNumerosRandom3, setImgNumerosRandom3] = useState(null);
    const [imgNumerosRandom4, setImgNumerosRandom4] = useState(null);
    const [imgNumerosRandom5, setImgNumerosRandom5] = useState(null);
    const [imgNumerosRandom6, setImgNumerosRandom6] = useState(null);
    const [imgNumerosRandom7, setImgNumerosRandom7] = useState(null);
    const [imgNumerosRandom8, setImgNumerosRandom8] = useState(null);

    const [hoverCarton1, setHoverCarton1] = useState(false);
    const [hoverCarton2, setHoverCarton2] = useState(false);
    const [hoverCarton3, setHoverCarton3] = useState(false);
    const [hoverCarton4, setHoverCarton4] = useState(false);
    const [hoverCarton5, setHoverCarton5] = useState(false);
    const [hoverCarton6, setHoverCarton6] = useState(false);
    const [hoverCarton7, setHoverCarton7] = useState(false);
    const [hoverCarton8, setHoverCarton8] = useState(false);

    const [numeroRandomImgSelect, setNumeroRandomImgSelect] = useState(null);

    const [modalIsOpen, setIsOpen] = React.useState(false);


    let numerosRandom = [];    

    const data = useServiceData("https://"+process.env.REACT_APP_BACKEND_HOST+"/api/juego/"+codigoURL);   

    function openModal() {
        setTimeout(function(){
            setIsOpen(true);
        }, 1000);
        
    }

  
    function closeModal() {
      setIsOpen(false);
    }

    const containerStyle= {
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${IMAGES.background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
}

    const logos= {
        height:"150px"
    }
    
    const imagenLogo= {
        width:"150px",
        height:"150px",
        float:"right",
        paddingTop:"35px",
        paddingRight:"35px",
        content: `url(${IMAGES.logororomiem})`
    }
    
    
    const pantallaNegra= {
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }
    
    
    const imagenLogoCentro= {
        width:"150px",
        height:"150px",
        float:"center",
        content: `url(${IMAGES.logororomiem})`,
        alignContent: 'center',
        paddingTop: '30vh',
        display: 'inline-grid'
    }
    
    const textoLogo= {
        float:"left",
        paddingTop:"55px",
        paddingLeft:"35px",
        content: `url(${IMAGES.textologo})`
    }
    
    const codigoUtilizado={
        color: 'white',
        textAlign: 'center',
        fontSize: 'xx-large',
        fontFamily: 'fantasy',
        paddingTop: '30vh',
    }
    
    const codigo={
        color: 'white',
        textAlign: 'center',
        fontSize: 'x-large',
        fontFamily: 'fantasy',
        float: 'inline-start',
        paddingLeft: '35px',
    }

    useEffect(() => {
        numerosRandom = generarArregloRandom();
    }, []);

    const onGanador = (winner, flag) => {
        if(winner!=="" && winner !== 'SIGA PARTICIPANDO'){
            setMostrarMsgGanador(true)  
        }        
    };

   

    const generarArregloRandom = () => {
    let results = []
    // mientras sea menor al limite
    while (results.length < 8) {
        // obtenemos un número aleatorio entre 0 y 10
    	const number = Math.floor(Math.random() * 8);
      
      // Lo agregamos al arreglo si el numero no existe en el listado
      if (!results.includes(number)) {
      	results.push(number);
      }
    }

    let array = [IMAGES.raspaganaseleccion1,
        IMAGES.raspaganaseleccion2,
        IMAGES.raspaganaseleccion3,
        IMAGES.raspaganaseleccion4,
        IMAGES.raspaganaseleccion5,
        IMAGES.raspaganaseleccion6,
        IMAGES.raspaganaseleccion7,
        IMAGES.raspaganaseleccion8
    ];

    setNumerosRandom1(array.at(results.at(0)));
    setNumerosRandom2(array.at(results.at(1)));
    setNumerosRandom3(array.at(results.at(2)));
    setNumerosRandom4(array.at(results.at(3)));
    setNumerosRandom5(array.at(results.at(4)));
    setNumerosRandom6(array.at(results.at(5)));
    setNumerosRandom7(array.at(results.at(6)));
    setNumerosRandom8(array.at(results.at(7)));

    setImgNumerosRandom1(results.at(0)+1);
    setImgNumerosRandom2(results.at(1)+1);
    setImgNumerosRandom3(results.at(2)+1);
    setImgNumerosRandom4(results.at(3)+1);
    setImgNumerosRandom5(results.at(4)+1);
    setImgNumerosRandom6(results.at(5)+1);
    setImgNumerosRandom7(results.at(6)+1);
    setImgNumerosRandom8(results.at(7)+1);

    }

    const imagenBotonRaspaGana1= {
        content: `url(${numerosRandom1})`,
        width:"200px",
        transform: `${hoverCarton1 ? 'scale(1.5,1.5)' : null}`,
        transition:"1s"
    }

    const imagenBotonRaspaGana2= {
        content: `url(${numerosRandom2})`,
        width:"200px",
        transform: `${hoverCarton2 ? 'scale(1.5,1.5)' : null}`
    }

    const imagenBotonRaspaGana3= {
        content: `url(${numerosRandom3})`,
        width:"200px",
        transform: `${hoverCarton3 ? 'scale(1.5,1.5)' : null}`
    }

    const imagenBotonRaspaGana4= {
        content: `url(${numerosRandom4})`,
        width:"200px",
        transform: `${hoverCarton4 ? 'scale(1.5,1.5)' : null}`
    }

    const imagenBotonRaspaGana5= {
        content: `url(${numerosRandom5})`,
        width:"200px",
        transform: `${hoverCarton5 ? 'scale(1.5,1.5)' : null}`
    }

    const imagenBotonRaspaGana6= {
        content: `url(${numerosRandom6})`,
        width:"200px",
        transform: `${hoverCarton6 ? 'scale(1.5,1.5)' : null}`
    }

    const imagenBotonRaspaGana7= {
        content: `url(${numerosRandom7})`,
        width:"200px",
        transform: `${hoverCarton7 ? 'scale(1.5,1.5)' : null}`
    }

    const imagenBotonRaspaGana8= {
        content: `url(${numerosRandom8})`,
        width:"200px",
        transform: `${hoverCarton8 ? 'scale(1.5,1.5)' : null}`
    }

    const customStylesModal = {
        content: {
          width: '440px',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'unset',
          border: 0,
        },
      };

    return (
        <div
        style={containerStyle}>
            <div
            style={{
            alignContent:"center"
            }}>
              
                {mostrarMsgGanador && <Confetti width={window.innerWidth} height={window.innerHeight} />}
                {data && data.habilitado ? (
                    <div>
                    <div style={logos}>
                    <div style={textoLogo}></div>
                    <div style={imagenLogo}></div>
                    
                    </div>
                    <div style={codigo}>CODIGO: {codigoURL}</div>
                    <div style={{
                    paddingTop:"80px"
                    }}>

                <img
                    style={imagenBotonRaspaGana1}
                    onMouseOut={() => setHoverCarton1(false)}
                    onMouseOver={() => setHoverCarton1(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton1(true)
                        setNumeroRandomImgSelect(imgNumerosRandom1)
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana2}
                    onMouseOut={() => setHoverCarton2(false)}
                    onMouseOver={() => setHoverCarton2(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton2(true)
                        setNumeroRandomImgSelect(imgNumerosRandom2)
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana3}
                    onMouseOut={() => setHoverCarton3(false)}
                    onMouseOver={() => setHoverCarton3(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton3(true)
                        setNumeroRandomImgSelect(imgNumerosRandom3)
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana4}
                    onMouseOut={() => setHoverCarton4(false)}
                    onMouseOver={() => setHoverCarton4(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton4(true)
                        setNumeroRandomImgSelect(imgNumerosRandom4);
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana5}
                    onMouseOut={() => setHoverCarton5(false)}
                    onMouseOver={() => setHoverCarton5(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton5(true)
                        setNumeroRandomImgSelect(imgNumerosRandom5)
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana6}
                    onMouseOut={() => setHoverCarton6(false)}
                    onMouseOver={() => setHoverCarton6(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton6(true)
                        setNumeroRandomImgSelect(imgNumerosRandom6)
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana7}
                    onMouseOut={() => setHoverCarton7(false)}
                    onMouseOver={() => setHoverCarton7(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton7(true)
                        setNumeroRandomImgSelect(imgNumerosRandom7)
                    }}
                    loading="lazy"
                />

                <img
                    style={imagenBotonRaspaGana8}
                    onMouseOut={() => setHoverCarton8(false)}
                    onMouseOver={() => setHoverCarton8(true)}
                    onClick={()=>{
                        openModal()
                        setHoverCarton8(true)
                        setNumeroRandomImgSelect(imgNumerosRandom8)
                    }}
                    loading="lazy"
                />

               
                    </div>
                    </div>
                ):(                   
                    data && data.habilitado === false ? 
                    (
                        <div style={codigoUtilizado}>El codigo ya fue utilizado..</div>
                    ):(
                        <div style={pantallaNegra}>
                            <div style={imagenLogoCentro}></div>
                        </div>
                    )        
            
            
                )}
                <div>
                </div> 
            </div>
        <Modal
        style={customStylesModal}
        isOpen={modalIsOpen}>
                <>
                <CartonRaspadita
                        premio='$50' 
                        habilitado='true' 
                        onGanador={(winner,flag) => onGanador(winner,flag)} 
                        imgSeleccionada={numeroRandomImgSelect}               
        />
                </>
            </Modal>

</div>

    );
};
export default Raspadita;