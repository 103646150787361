import React, { useState } from "react";
import IMAGES from "./assets";
import "./styles.css";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CasinoIcon from '@mui/icons-material/Casino';
import { useParams, useNavigate  } from "react-router-dom"
import axios from "axios";
import ambiente from './assets/ambiente.mp3';
import useServiceData from "./useServiceData";

const InicioCodigo = () => {

let params = useParams()

const [codigoURL, setCodigoURL] = useState(params.codigo);
const data = useServiceData("https://"+process.env.REACT_APP_BACKEND_HOST+"/api/juego/"+codigoURL); 
const audioAmbiente = new Audio(ambiente);
const pantallaNegra= {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}
const containerStyle= {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${IMAGES.background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}

const imagenLogoCentro= {
    width:"150px",
    height:"150px",
    float:"center",
    content: `url(${IMAGES.logororomiem})`,
    alignContent: 'center',
    paddingTop: '30vh',
    display: 'inline-grid'
}

const alineacionBtn= {
  paddingTop: '30px'
}


const [isStarted, setStarted] = useState(false);
const [textoBtn, setTextoBtn] = useState("JUGAR");
const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 2.5,
    backgroundColor: '#d4af37',
    borderColor: '#d4af37',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#d4af37',
      borderColor: '#0062cc',
      boxShadow: 'none',
      color: 'white',
      fontSize: 30
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  });

  const codigoUtilizado={
    color: 'white',
    textAlign: 'center',
    fontSize: 'xx-large',
    fontFamily: 'fantasy',
    paddingTop: '30vh',
}
const navigate = useNavigate();
const jugar = () => { 
  audioAmbiente.loop=true;
  audioAmbiente.play();
  if(data.juego === 'RASPADITA'){
    navigate("/raspadita/"+codigoURL);
  } else if(data.juego === 'RULETA'){
    navigate("/ruleta/"+codigoURL);
  }
  
}
  
    return (
        <div
        style={containerStyle}>
            <div
            style={{
            alignContent:"center"
            }}>              
            <div style={pantallaNegra}>
                <div style={imagenLogoCentro}></div>
                <div style={alineacionBtn}>

                {data && data.habilitado ? (
                  <BootstrapButton  variant="contained" 
                  disableRipple disabled = {isStarted} 
                  startIcon={<CasinoIcon />} 
                  endIcon={<CasinoIcon />} 
                  onClick={()=>jugar()}>
                  {textoBtn}
                  </BootstrapButton>
                ):(                   
                    data && data.habilitado === false ? 
                    (
                        <div style={codigoUtilizado}>El codigo ya fue utilizado..</div>
                    ):(
                      <div></div>                      
                    )

                )}

                </div>
                
            </div>
 
            </div>
        </div>
    );
}
export default InicioCodigo;
